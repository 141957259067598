import { createMuiTheme } from '@material-ui/core/styles'



const CustomTheme = createMuiTheme({
    direction: 'rtl',
});
  
export default CustomTheme;


